import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {  
      about: "About me",
      contact: "Contact",
      education: "Education",
      skills: "Skills",
      projects: "Projects",
      projectnotFound: "Project not found",
      projectrepo: "Project repository",
      githubrepo: "GitHub repository",
      used: "Technologies used:",
      solo: "Self-made project",
      team: "Project made in team",
      teamrole: "My role:",
      screenshots: "Screenshots:",
      gallery: "Project gallery:",
      downloadgame: "Download game",
      gamesite: "Game website",
      downloadapp: "Download app here",
      //about me
      welcome: "Welcome to my internet site!",
      clickabove: "Click on buttons above, to see more about me :)",
      mygithub: "My GitHub profile",
      //education
      studies: "Engineering studies",
      pans: "State University of Applied Sciences in Krosno",
      computersience: "Computer science - specialization: network information systems",
      highschool: "High school",
      zse: "Technical school no. 5 in Krosno",
      zseclass: "Electronics technician",
      //skills
      certs: "Certificates",
      computernetworks: "Computer networks",
      databases: "Databases",
      oop: "Object-oriented programming",
      oke: "District Examination Commission in Kraków",
      e06: "Installation of electronic devices",
      e20: "Operation of electronic equipment",        
      //months
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
      
      //PROJECTS TRANSLATIONS
      /////JAVA projects translations
      ////////Bibioteka
      java1button: "📚 Library system in SpringBoot/React",
      java1name: "Library system with database",
      java1desc1: "A library system with a database to manage readers, book collection and loans. The implementation also includes an event log, authorization with users with different permissions.",
      java1desc2: "The backend was created using SpringBoot integrated with a PostgreSQL database. The frontend is a web-based single-window application created using the React library.",
      java1desc3: "The project includes sample JUnit unit tests and JavaDoc documentation. The project can be deployed using Docker (3 integrated containers: server, client, database)",
        ////////Spacegame
        java2button: "🛸 Shoot 'em up game in Swing",
        java2name: "Shoot 'em up game \"Space Game\" ",
        java2desc1: "A simple shoot 'em up game in which the player controls a spaceship. The aim of the game is to shoot down alien opponents, while avoiding their attacks and collecting various bonuses.",
        java2desc2: "Main features:",
        java2desc3: "levels",
        java2desc4: "bosses",
        java2desc5: "enemy types",
        java2desc6: "spaceships to choose from",
        java2desc7: "bonuses to collect",
        java2desc8: "scoreboard",
        java2desc9: "support for english and polish languages",
       ////////AutoClicker
       java3desc: "Simple desktop application for automatical mouse clicking.",
       ////////BotDiscord
       java4name: "Bot for Discord server",
       java4desc1: "Bot for Discord communicator server.",
       java4desc2: "The bot has commands through which server users can, among other things:",
       java4desc3: "get weather information in any location",
       java4desc4: "ask questions and get answers (ChatGPT)",
       java4desc5: "listen to songs by typing in a link, the bot joins the user's voice channel and plays the songs.",
       java4desc6: "The bot allows you to add playlists and manage your song queue.",
       java4desc7: "currently the bot supports links from SoundCloud (YouTube support has been deprecated)",
       ////RMITelnet
       java5name: "Java RMI Telnet Client/Server",
       java5desc1: "Console server and client applications implementing simplified telnet functionality with optional connection encryption.",
/////other projects translations
        ////ue5 game
        p1name: "Third-person 3D platform-puzzle game",
        p1desc1: "Gem Tamer is a 3D platform-puzzle game. The main goal is to collect white gems to reach the finish line. The levels contain obstacles: enemies, traps and colored doors blocking the passages. The main character can collect colored gems to open doors, eliminate enemies or mix them with others to create new colors.",
        p1desc2: "The game contains 5 interesting levels, containing both arcade elements and logic puzzles. The game boards also hide secrets that the player can discover as an additional challenge.",
        p1engineer: "Engineering thesis project",
        ////android game
        p3name: "Two-player mobile game to test players' reactions",
        p3desc1: "2 Player React is a two-player game whose goal is to collect points, received for showing faster reactions in tasks.",
        p3desc2: "The game screen is divided into 3 parts: 2 buttons for each player and the center. The game is based on the player pressing the button at the right moment, then he gets a point. If the player presses the button at the wrong moment, one point is deducted.",
        p3desc3: "The game has 3 mini-games implemented:",
        p3desc4: "“White color” - The player must press the button at the moment when the center turns white. The point is awarded to the player who pressed the button faster.",
        p3desc5: "“Match color” - The player has to press the button at the moment when the color name, which is the same as the font color, is displayed in the center.",
        p3desc6: "“City States”- The player must press the button when the name of a state is displayed in the center along with the correct capital of that state.",       
        ////////chatc#
        p4name: "Chat TCP/IP",
        p4desc1: "A simple desktop chat application using TCP and IP protocols.",
        ////////c/cpp
        ////////////////esp32
        p51name: "ESP32 Weather Station",
        p51desc1: "Weather station made on ESP32. The station takes measurements of: air temperature, pressure, humidity, eclipse, particulate matter: PM1.0, PM2.5, PM10.0",
        p51desc2: "ESP32 sends the measurement results to the MQTT server via WiFi.",
        team2: "Project done in a team of two",
        ////////////////ftp c
        p52name: "Client/Server FTP",
        p52desc1: "Console client and FTP server applications for Windows and Linux.",
        p52desc2: "It is possible to transfer binary files both between the same and different operating systems.",
        ////////retact
        p6name: "This site :)",
        p6desc1: "",
        p6desc2: "",
    },
  },
  pl: {
    translation: {
        about: "O mnie",
        contact: "Kontakt",
        education: "Wykształcenie",
        skills: "Umiejętności",
        projects: "Projekty",
        projectnotFound: "Projekt nie znaleziony",
        projectrepo: "Repozytorium projektu",
        githubrepo: "Repozytorium GitHub",
        used: "Wykorzystane technologie:",
        solo: "Projekt wykonany samodzielnie",
        team: "Projekt wykonany w zespole",
        teamrole: "Moja rola w zespole:",
        screenshots: "Zrzuty ekranu:",
        gallery: "Galeria projektu:",
        downloadgame: "Pobierz grę",
        gamesite: "Strona gry",
        downloadapp: "Kliknij tutaj, aby pobrać aplikację",
        //about me
        welcome: "Witaj na mojej stronie internetowej!",
        clickabove: "Kliknij w przyciski powyżej, aby dowiedzieć się o mnie więcej! :)",
        mygithub: "Mój profil na GitHub",
        //education
        studies: "Studia inżynierskie",
        pans: "Państwowa Akademia Nauk Stosowanych w Krośnie",
        computersience: "Informatyka - specjalność: Sieciowe Systemy Informatyczne",
        highschool: "Szkoła średnia",
        zse: "Technikum nr 5 w Krośnie",
        zseclass: "Technik elektronik",
        //skills
        certs: "Certifikaty",
        computernetworks: "Sieciowe systemy informatyczne",
        databases: "Bazy Danych",
        oop: "Programowanie obiektowe",
        oke: "Okręgowa Komisja Egzaminacyjna w Krakowie",
        e06: "Wykonywanie instalacji urządzeń elektronicznych",
        e20: "Eksploatacja urządzeń elektronicznych",
        //months
        january: "Styczeń",
        february: "Luty",
        march: "Marzec",
        april: "Kwiecień",
        may: "Maj",
        june: "Czerwiec",
        july: "Lipiec",
        august: "Sierpień",
        september: "Wrzesień",
        october: "Pażdziernik",
        november: "Listopad",
        december: "Grudzień",
        //PROJECTS TRANSLATIONS
        /////JAVA projects translations
        ////////Bibioteka
        java1button: "📚 System biblioteczny w SpringBoot/React",
        java1name: "System biblioteczny z bazą danych",
        java1desc1: "System biblioteczny z bazą danych, pozwalający na zarządzanie czytelnikami, księgozbiorem oraz wypożyczeniami. Implementacja zawiera też dziennik zdarzeń, autoryzację z użytkownikami z różnymi uprawnieniami.",
        java1desc2: "Backend został stworzony za pomocą SpringBoot'a zintegrowanego z bazą danych PostgreSQL. Frontend jest webową aplikacją jednookienkową stworzoną za pomocą biblioteki React.",
        java1desc3: "Projekt zawiera przykładowe testy jednostkowe JUnit oraz dokumentację JavaDoc. Projekt może być wdrożony za pomocą Dockera (3 zintegrowane kontenery: serwer, klient, baza danych)",
        ////////Spacegame
        java2button: "🛸 Gra Shoot 'em up w Swing",
        java2name: "Gra Shoot 'em up \"Space Game\" ",
        java2desc1: "Prosta gra z gatunku shoot 'em up, w której gracz wciela się w statek kosmiczny. Celem gry jest zestrzeliwanie przeciwników-kosmitów, unikając przy tym ich ataków oraz zbierając różne bonusy.",
        java2desc2: "Gra zawiera:",
        java2desc3: "poziomów",
        java2desc4: "bossów",
        java2desc5: "rodzaje przeciwników",
        java2desc6: "statków do wyboru",
        java2desc7: "bonusów do zbierania",
        java2desc8: "tabela najlepszych wyników",
        java2desc9: "języki: angielski i polski",
        ////////AutoClicker
        java3desc: "Prosta desktopowa aplikacja do automatycznego klikania przyciskami myszy.",
        ////////BotDiscord
        java4name: "Bot dla serwera Discord",
        java4desc1: "Bot dla serwera komunikatora Discord.",
        java4desc2: " Bot posiada komendy dzięki którym użytkownicy serwera mogą m.in.:",
        java4desc3: "uzyskać informacje o pogodzie w dowolnej miejscowości",
        java4desc4: "zadawać pytania i otrzymywać odpowiedź (ChatGPT)",
        java4desc5: "odsłuchiwać utwory przez wpisanie linka, bot dołącza do kanału głosowego użytkownika i odgrywa utwory.",
        java4desc6: "bot pozwala na dodawanie playlist oraz zarządzanie kolejką utworów.",
        java4desc7: "aktualnie bot wpiera linki z serwisu SoundCloud (obsługa YouTube została wycofana)",
        ////////RMITelnet
        java5name: "Java RMI Telnet Klient/Serwer",
        java5desc1: "Konsolowe aplikacje serwera i klienta realizujące funkcję uproszczonego telnetu z opcjonalnym szyfrowaniem połączenia.",
/////other projects translations
        ////ue5 game
        p1name: "Platformowo-logiczna gra 3D w perspektywie trzeciej osoby",
        p1desc1: "Gem Tamer to gra platformowo-logiczna 3D. Głównym celem jest zbieranie białych klejnotów, aby dotrzeć do mety. Poziomy zawierają przeszkody: wrogów, pułapki i kolorowe drzwi blokujące przejścia. Główny bohater może zbierać kolorowe klejnoty, aby otwierać drzwi, eliminować wrogów lub mieszać je z innymi, aby tworzyć nowe kolory.",
        p1desc2: "Gra zawiera 5 ciekawych poziomów, zawierających zarówno elementy zręcznościowe jak i zagadki logiczne. Plansze skrywają też sekrety, które gracz może odkrywać w ramach dodatkowego wyzwania.",
        p1engineer: "Projekt inżynierski",
        ////android game
        p3name: "Dwuosobowa gra mobilna testująca reakcje graczy",
        p3desc1: "2 Player React to gra dwuosobowa, której celem jest zbieranie punktów, otrzymywanych za wykazywaniem się szybszą reakcją w zadaniach.",
        p3desc2: "Ekran gry jest podzielony na 3 części: 2 przyciski dla każdego z graczy oraz środka. Gra polega na przyciśnięciu przycisku przez gracza w odpowiednim momencie, wtedy otrzymuje punkt. Jeśli gracz wciśnie przycisk w złym momencie, zostaje mu odjęty jeden punkt.",
        p3desc3: "Gra posiada zaimplementowane 3 mini-gry:",
        p3desc4: "„Biały kolor” – gracz musi przycisnąć przycisk w momencie, gdy środek zmieni kolor na biały. Punkt uzyskuje gracz, który wcisnął przycisk szybciej.",
        p3desc5: "„Dopasuj kolor” - gracz musi przycisnąć przycisk w momencie, gdy na środku wyświetli się nazwa koloru, która jest tożsama z kolorem czcionki. ",
        p3desc6: "„Państwa Miasta”- gracz musi przycisnąć przycisk w momencie, gdy na środku wyświetli się nazwa państwa wraz z poprawną stolicą tego państwa.",       
        ////////chatc#
        p4name: "Czat TCP/IP",
        p4desc1: "Prosta desktopowa aplikacja czatu wykorzystująca protokoły TCP i IP.",
        ////////c/cpp
        ////////////////esp32
        p51name: "Stacja pogodowa ESP32",
        p51desc1: "Stacja pogodowa wykonana na ESP32. Stacja wykonuje pomiary: temperatury powietrza, ciśnienia, wilgotności, zaćmienia, pyłów zawieszonych: PM1.0, PM2.5, PM10.0",
        p51desc2: "ESP32 wysyła wyniki pomiarów na serwer MQTT za pośrednictwem WiFi.",
        team2: "Projekt wykonany w zespole dwuosobowym",
        ////////////////ftp c
        p52name: "Klient/Serwer FTP",
        p52desc1: "Aplikacje konsolowe klienta i serwera FTP dla Windows oraz Linux.",
        p52desc2: "Możliwe jest przesyłanie plików binarnych zarówno pomiędzy tymi samymi, jak i różnymi systemami operacyjnymi.",
        ////////retact
        p6name: "Ta strona :)",
        p6desc1: "",
        p6desc2: "",

    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pl",
  interpolation: { escapeValue: false },
});

export default i18n;
