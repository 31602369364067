import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./i18n.js";
import "./App.css";

const ImageGallery = ({ images, galleryId  }) => {
  const [index, setIndex] = useState(0);
  const nextImage = () => setIndex((index + 1) % images.length);
  const prevImage = () => setIndex((index - 1 + images.length) % images.length);
  useEffect(() => {
    setIndex(0); // Resetuje index do 0 po zmianie projektu
  }, [galleryId]);
  return (
    <div>
      <div className="gallery-container" >
        <button className="gallery-button" onClick={prevImage}>◀️</button>
        <img className="center-image" src={images[index]} alt={`gallery_image_${index + 1}`} />
        <button className="gallery-button" onClick={nextImage}>▶️</button>
      </div>
      <div className="gallery-counter">
          <span>[{index + 1} / {images.length}]</span>
        </div>
    </div>
  );
};


const Home = () =>{
  const {t} = useTranslation();
  return <div><h2 className="headertext">{t("about")}</h2><hr></hr>
  <p className="headertext">{t("welcome")} </p>
  <p className="headertext">{t("clickabove")} </p>
  <div className="gallery-container">
    <a href="https://github.com/MeHow2k"> {t("mygithub")}</a>
    <img width="50" height="50" src="/images/githublogo.png" alt="GitHub logo"></img>
  </div>
  </div>;
}
const Contact = () => {
  const {t} = useTranslation();
  return <div><h2 className="headertext">{t("contact")}</h2><hr></hr>
  <p className="headertext">Email: michalpasieka2000@gmail.com</p></div>;
}
const Education = () => {
  const {t} = useTranslation();
  return <div><h2 className="headertext">{t("education")}</h2><hr></hr>
    <div>
      <h3 className="headertext">{t("studies")}</h3>
      <p className="headertext">{t("pans")}</p>
      <p className="headertext">{t("computersience")}</p>
      <p className="headertext">2021-2025</p>
      <hr></hr>
    </div>
    <div>
      <h3 className="headertext">{t("highschool")}</h3>
      <p className="headertext">{t("zse")}</p>
      <p className="headertext">{t("zseclass")}</p>
      <p className="headertext">2016-2020</p>
      <hr></hr>
    </div>
  </div>;
}
const Skills = () => {
  const {t} = useTranslation();
  return <div><h2 className="headertext">{t("skills")}</h2><hr></hr>
    <div>
      <p className="headertext">Java - Swing, SpringBoot, JPA, Hibernate, JavaDoc, JUnit, Maven, Android, JDA</p>
      <p className="headertext">Unreal Engine 5 - Blueprint</p>
      <p className="headertext">SQL HTML Javascript C C++ C# </p>
      <p className="headertext">Docker GIT</p>
      <p className="headertext">Windows Linux</p>
      <p className="headertext">{t("computernetworks")}</p>
      <hr></hr>
    </div>
    <div>
      <h2 className="headertext">{t("certs")}</h2><hr></hr>
      <h3 className="headertext">Microsoft IT Academy</h3>
      <p className="headertext">ITA-103 - {t("databases")}</p>
      <p className="headertext">ITA-105 - {t("oop")}</p><hr></hr>
      <h3 className="headertext">{t("oke")}</h3>
      <p className="headertext">E.06- {t("e06")}</p>
      <p className="headertext">E.20- {t("e20")}</p>
      <hr></hr>
    </div>
  </div>;
}
const JavaProjects = () => {
  const [selectedJavaProject, setSelectedJavaProject] = useState("java1"); 
  const {t} = useTranslation();
  const handleJavaProjectClick = (project) => {
    setSelectedJavaProject(project);
  };
  const javaprojects = [
    { id: "java1", name: t("java1button"), description:(<>
     <div>
      <h2 className="headertext">{t("java1name")}</h2>
      <img className="center-banner" alt="bibliotekabanner" src="images/java/biblioteka/spring1.png"></img>
    </div>
  
    <div className="vertical-container">
      <div className="vertical-text">
        <p>{t("java1desc1")}</p>
        <p>{t("java1desc2")}</p>
        <p>{t("java1desc3")}</p>
          <a href="https://github.com/MeHow2k/SpringBootProjekt" >{t("githubrepo")}</a>
      </div>
  
      {/* <div className="vertical-video" id="video_embed">
            <iframe title="gemtamervideo" frameborder="0" allowfullscreen="" src="//www.youtube.com/embed/yucfgeoYGic" ></iframe>
      </div> */}
    </div>
  
    <div>
      <p className="headertext"> {t("screenshots")} </p>
      <ImageGallery images={[
              "/images/java/biblioteka/spring2.png",
              "/images/java/biblioteka/spring3.png",
              "/images/java/biblioteka/spring4.png",
              "/images/java/biblioteka/spring1.png",
              
            ]} galleryId={"java1"} />
      <div>
  
     
        
         <div className="gallery-container">
          <a href="https://github.com/MeHow2k/SpringBootProjekt" >{t("githubrepo")}</a>
          <img width="32" height="32" src="/images/githublogo.png" alt="GitHub logo"></img>
         </div>
       
        <p className="subtitle"> {t("used")} </p>
        <p className="subtitle"> Java, Spring Boot, REST, React, SpringSecurity, JPA, Hibernate, PostgeSQL, JavaDoc, JUnit, Docker </p>
        <p className="subtitle"> {t("solo")}. {t("November")} 2024</p>
      </div>
    </div>
     </>)},
    { id: "java2", name: t("java2button"), description:(<>
      <div>
       <h2 className="headertext">{t("java2name")}</h2>  
     </div>
   
     <div className="vertical-container">
       <div className="vertical-text">
         <p>{t("java2desc1")}</p>
          <p>{t("java2desc2")}</p>
          <p>-50 {t("java2desc3")}</p>
          <p>-5 {t("java2desc4")}</p>
          <p>-3 {t("java2desc5")}</p>
          <p>-5 {t("java2desc6")}</p>
          <p>-8 {t("java2desc7")}</p>      
          <p>-{t("java2desc8")}</p>   
          <p>-{t("java2desc9")}</p>  
         
           <a href="https://github.com/MeHow2k/SpaceGame/releases/tag/v1.0.3" >{t("downloadgame")}!</a>
       </div>
   
        <div className="vertical-video" id="video_embed">
             <iframe title="spacegamevideo" frameborder="0" allowfullscreen="" src="//www.youtube.com/embed/Sk6LjUUlLeI " ></iframe>
       </div> 
     </div>
   
     <div>
       <p className="headertext"> {t("githubrepo")} </p>
       <ImageGallery images={[        
               "/images/java/spacegame/sg0.jpg",
               "/images/java/spacegame/sg1.jpg",
               "/images/java/spacegame/sg2.jpg",
               "/images/java/spacegame/sg3.jpg",
               "/images/java/spacegame/sg4.jpg",
               "/images/java/spacegame/sg5.jpg",
             ]} galleryId={"java2"}/>
       <div>
   
       <div>
         <p className="subtitle"> {t("gamesite")}: </p>       
          <div className="gallery-container" id="itchio_embed">
            <iframe title="spacegameembed" width="552" height="167" frameborder="0" src="https://itch.io/embed/2690131"><a href="https://teampdm.itch.io/space-game">Space Game by TeamPDM</a></iframe>
          </div>    
         </div>
         <div className="gallery-container">
          <a href="https://github.com/MeHow2k/SpaceGame" >{t("githubrepo")}</a>
          <img width="32" height="32" src="/images/githublogo.png" alt="GitHub logo"></img>
         </div>
         <p className="subtitle"> {t("used")} </p>
         <p className="subtitle"> Java, Swing, Intellij </p>
         <p className="subtitle"> {t("team")}. {t("teamrole")} Main Programmer, Game Designer, Product Owner. {t("may")} 2024</p>
       </div>
     </div>
      </>)},
    { id: "java3", name: "🖱 Auto Clicker", description:(<>
      <div>
       <h2 className="headertext">Auto Clicker</h2>
     </div>
   
     <div className="vertical-container">
       <div className="vertical-text">
         <p>{t("java3desc")}</p>
         <p></p>
           <a href="https://github.com/MeHow2k/JavaAutoClicker/releases/tag/v1.0.1" >{t("downloadapp")}</a>
       </div>
   
      
     </div>
     <div>
       <p className="headertext"> {t("screenshots")} </p>
       <ImageGallery images={[
               "/images/java/autoclicker/autoclick1.png",
               "/images/java/autoclicker/autoclick2.png",
             ]} galleryId={"java3"}/>
       <div>
   
       <div>
           <div className="gallery-container">
             <a href="https://github.com/MeHow2k/JavaAutoClicker/">{t("githubrepo")}</a>
             <img width="32" height="32" src="/images/githublogo.png" alt="GitHub logo"></img>
           </div>
         </div>
         <p className="subtitle"> {t("used")} </p>
         <p className="subtitle"> Java, Swing, AWT</p>
         <p className="subtitle"> {t("solo")}. {t("march")} 2024</p>
       </div>
     </div>
     </>)},
    { id: "java4", name: "🤖 "+t("java4name"), description:(<>
      <div>
       <h2 className="headertext">{t("java4name")}</h2>
     </div>
   
     <div className="vertical-container">
       <div className="vertical-text">
         <p>{t("java4desc1")}</p><p>{t("java4desc2")}</p>
         <p>-{t("java4desc3")}</p>
         <p>-{t("java4desc4")}</p>
         <p>-{t("java4desc5")}</p>
         <p>-{t("java4desc6")}</p>
         <p>-{t("java4desc7")} </p>
         <p></p>
           <a href="https://github.com/MeHow2k/DiscordBot/" >{t("projectrepo")}</a>
       </div>
   
      
     </div>
     <div>
       <p className="headertext"> {t("screenshots")} </p>
       <ImageGallery images={[
               "/images/java/botdiscord/bot2.png",
               "/images/java/botdiscord/bot3.png",
               "/images/java/botdiscord/bot4.png",
             ]} galleryId={"java4"}/>
       <div>
   
       <div>     
           <div className="gallery-container" id="itchio_embed">
             <a href="https://github.com/MeHow2k/DiscordBot/">{t("githubrepo")}</a>
             <img width="32" height="32" src="/images/githublogo.png" alt="GitHub logo"></img>
           </div>
         </div>
         <p className="subtitle"> {t("used")} </p>
         <p className="subtitle"> Java, JDA (Java Discord API), Swing, AWT, Lavaplayer, REST, ChatGPT API</p>
         <p className="subtitle"> {t("solo")}. {t("february")} 2024</p>
       </div>
     </div>
     </>)},
    { id: "java5", name: "🖥 "+ t("java5name"), description:(<>
      <div>
       <h2 className="headertext">{t("java5name")}</h2>
     </div>
   
     <div className="vertical-container">
       <div className="vertical-text">
         <p>{t("java5desc1")}</p>
         <p></p>
           <a href="https://github.com/MeHow2k/Java_RMI_Telnet_ClientServer/" >{t("projectrepo")}</a>
       </div>
   
      
     </div>
     <div>
       <p className="headertext"> {t("screenshots")} </p>
       <ImageGallery images={[
               "/images/java/rmi/rmi1.png",
               "/images/java/rmi/rmi2.png",
             ]} galleryId={"java5"}/>
       <div>
   
       <div>
           <div className="gallery-container">
             <a href="https://github.com/MeHow2k/Java_RMI_Telnet_ClientServer/">{t("githubrepo")}</a>
             <img width="32" height="32" src="/images/githublogo.png" alt="GitHub logo"></img>
           </div>
         </div>
         <p className="subtitle"> {t("used")} </p>
         <p className="subtitle"> Java, RMI, Socket, Cipher</p>
         <p className="subtitle"> {t("solo")}. {t("february")} 2024</p>
       </div>
     </div>
     </>)},
  ];
  return (
    <div>      
      <div>
      <nav className="nav-container">
          {javaprojects.map((project) => (
            <button 
              key={project.id} 
              onClick={() => handleJavaProjectClick(project)} 
              className="project-button"
            >
              {project.name}
            </button>
          ))}
        </nav><hr></hr>
        {selectedJavaProject && (
          <div>         
            <p>{selectedJavaProject.description}</p>
          </div>
        )}
      </div>
    </div>
  );
};




const Projects = () => {
  const [selectedProject, setSelectedProject] = useState("projekt1");
  const {t} = useTranslation();
  const projects = [

    { id: "projekt1", name: "🎮 Unreal Engine 5", description: (<>
    <div>
      <h2 className="headertext">{t("p1name")}</h2>
      <img className="center-banner" alt="gemtamerbanner" src="https://img.itch.zone/aW1nLzIwMDc0ODM3LnBuZw==/original/phov94.png"></img>
    </div>
  
    <div className="vertical-container">
      <div className="vertical-text">
        <p>{t("p1desc1")}</p>
        <p>{t("p1desc2")}</p>
          <a href="https://mehow2k.itch.io/gem-tamer" >{t("downloadgame")}!</a>
      </div>
  
      <div className="vertical-video" id="video_embed">
            <iframe title="gemtamervideo" frameborder="0" allowfullscreen="" src="//www.youtube.com/embed/yucfgeoYGic" ></iframe>
      </div>
    </div>
  
    <div>
      <p className="headertext"> {t("screenshots")} </p>
      <ImageGallery images={[
              "https://img.itch.zone/aW1hZ2UvMzM2MjIyNi8yMDA3NDUxMC5wbmc=/original/F6DX9i.png",
              "https://img.itch.zone/aW1hZ2UvMzM2MjIyNi8yMDA3NDUwNi5wbmc=/original/DRNNfh.png",
              "https://img.itch.zone/aW1hZ2UvMzM2MjIyNi8yMDA3NDUwOC5wbmc=/original/IoigE4.png",
              "https://img.itch.zone/aW1hZ2UvMzM2MjIyNi8yMDA3NDUxMS5wbmc=/original/EIvZB8.png",
              "https://img.itch.zone/aW1hZ2UvMzM2MjIyNi8yMDA3NDUwOS5wbmc=/original/Tfh03%2F.png",
              "https://img.itch.zone/aW1hZ2UvMzM2MjIyNi8yMDA3NDUwNy5wbmc=/original/I7wC%2Bb.png"
            ]} galleryId={"project1"} />
      <div>
  
      <div>
        <p className="subtitle"> {t("gamesite")}: </p>
          <div className="gallery-container" id="itchio_embed">
            <iframe title="GemTamerFrame" frameborder="0" src="https://itch.io/embed/3362226" width="552" height="167">
            <a href="https://mehow2k.itch.io/gem-tamer">Gem Tamer by MeHow2k</a></iframe> 
          </div>
        </div>
        <p className="subtitle"> {t("used")} </p>
        <p className="subtitle"> Unreal Engine 5, Blueprint, Unreal Montion Graphics, Behaviour Trees, Sequencer, Niagara System, Lumen, FAB. </p>
        <p className="subtitle"> {t("p1engineer")}, {t("solo")}. {t("december")} 2024</p>
      </div>
    </div>
  </>)},
  
    { id: "projekt2", name: "☕ Java", description: (<>
      <JavaProjects />
    </>) },
  
    { id: "projekt3", name: "📱 Android", description: (<>
      <div>
      <h2 className="headertext">{t("p3name")} </h2>
    </div>
  
    <div className="vertical-container">
      <div className="vertical-text">
        <p>{t("p3desc1")}</p>
        <p>{t("p3desc2")}</p> 
        <p>{t("p3desc3")}</p>
        <p>- {t("p3desc4")}</p>
        <p>- {t("p3desc5")} </p>
        <p>- {t("p3desc6")}</p>
  
          <a href="https://github.com/MeHow2k/2_Player_React/" >{t("downloadgame")}</a>
      </div>
  
      <div className="vertical-video" id="video_embed">
            <iframe title="2playerreactvideo" frameborder="0" allowfullscreen="" src="//www.youtube.com/embed/s-uMuRWAYEU" ></iframe>
      </div>
    </div>
    <div>
      <p className="headertext"> {t("screenshots")} </p>
      <ImageGallery images={[
              "/images/android/android1.jpg",
              "/images/android/android2.jpg",
              "/images/android/android3.jpg",
              "/images/android/android4.jpg",
              "/images/android/android5.jpg",
              "/images/android/android6.jpg",
              "/images/android/android7.jpg",
              "/images/android/android8.jpg",
              "/images/android/android9.jpg",
            ]} galleryId={"project3"}/>
      <div>
  
      <div>      
          <div className="gallery-container" id="itchio_embed">
            <a href="https://github.com/MeHow2k/2_Player_React/">{t("githubrepo")}</a>
            <img width="32" height="32" src="/images/githublogo.png" alt="GitHub logo"></img>
          </div>
        </div>
        <p className="subtitle"> {t("used")} </p>
        <p className="subtitle"> Java, Gradle, Android Studio</p>
        <p className="subtitle"> {t("solo")}. {t("january")} 2024</p>
      </div>
    </div>
    </>) },
    { id: "projekt4", name: "💿 C#", description: (<>
     <div>
      <h2 className="headertext">{t("p4name")}</h2>
    </div>
  
    <div className="vertical-container">
      <div className="vertical-text">
        <p>{t("p4desc1")}</p>
        <p></p>
          <a href="https://github.com/MeHow2k/Client-Server-TCP-Chat/" >{t("projectrepo")}</a>
      </div>
  
     
    </div>
    <div>
      <p className="headertext"> {t("screenshots")} </p>
      <ImageGallery images={[
              "/images/csharp/chat1.png",
              "/images/csharp/chat2.png",
              "/images/csharp/chat3.png",
              "/images/csharp/chat4.png",
              "/images/csharp/chat5.png"
            ]} galleryId={"project4"}/>
      <div>
  
      <div>
          <div className="gallery-container" >
            <a href="https://github.com/MeHow2k/Client-Server-TCP-Chat/">{t("githubrepo")}</a>
            <img width="32" height="32" src="/images/githublogo.png" alt="GitHub logo"></img>
          </div>
        </div>
        <p className="subtitle"> {t("used")} </p>
        <p className="subtitle"> C#, Windows Forms</p>
        <p className="subtitle"> {t("solo")}. {t("may")} 2023</p>
      </div>
    </div>
    </>) },
    { id: "projekt5", name: "👨🏻‍💻 C/C++", description: (<>
    <div>
        <div>
        <h2 className="headertext">{t("p51name")}</h2>
      </div>
    
      <div className="vertical-container">
        <div className="vertical-text">
          <p>{t("p51desc1")}</p>
          <p>{t("p51desc1")}</p>
            <a href="https://github.com/MeHow2k/Client-Server-TCP-Chat/" >{t("projectrepo")}</a>
        </div>
    
        
      </div>
      <div>
        <p className="headertext"> {t("gallery")} </p>
        <ImageGallery images={[
                "/images/ccpp/esp32/esp1.png",
                "/images/ccpp/esp32/esp2.png",
                "/images/ccpp/esp32/esp3.png",
              ]} galleryId={"project51"}/>
        <div>
    
        <div>
            <div className="gallery-container" id="itchio_embed">
              <a href="https://github.com/MeHow2k/StacjaPogodowaESP32">Repozytorium GitHub</a>
              <img width="32" height="32" src="/images/githublogo.png" alt="GitHub logo"></img>
            </div>
          </div>
          <p className="subtitle"> {t("used")} </p>
          <p className="subtitle"> C++, Arduino IDE, ESP32, MQTT</p>
          <p className="subtitle"> {t("team2")}. {t("january")} 2024</p>
        </div>
      </div>
     </div>
  <hr></hr>
     <div>
        <div>
        <h2 className="headertext">{t("p52name")}</h2>
      </div>
    
      <div className="vertical-container">
        <div className="vertical-text">
          <p>{t("p52desc1")}</p>
          <p>{t("p52desc2")}</p>
            <a href="https://github.com/MeHow2k/FTP_ClientServer/" >{t("projectrepo")}</a>
        </div>
    
        
      </div>
      <div>
        <p className="headertext"> {t("screenshots")} </p>
        <ImageGallery images={[
                "/images/ccpp/ftp/ftp1.png",
                "/images/ccpp/ftp/ftp2.png",
                "/images/ccpp/ftp/ftp3.png",
              ]} galleryId={"project52"}/>
        <div>
    
        <div>
            <div className="gallery-container" >
              <a href="https://github.com/MeHow2k/FTP_ClientServer">{t("githubrepo")}</a>
              <img width="32" height="32" src="/images/githublogo.png" alt="GitHub logo"></img>
            </div>
          </div>
          <p className="subtitle"> {t("used")} </p>
          <p className="subtitle"> C, Socket, TCP</p>
          <p className="subtitle"> {t("solo")}. {t("december")} 2023</p>
        </div>
      </div>
     </div>
     </>) },
      { id: "projekt6", name: "⚛ React", description: (<>
      <div>
          <h2 className="subtitle"> {t("p6name")} </h2>
          <div>
            <div className="gallery-container" >
              <a href="https://github.com/MeHow2k/Portfolio-Website-React">{t("githubrepo")}</a>
              <img width="32" height="32" src="/images/githublogo.png" alt="GitHub logo"></img>
            </div>
          </div>
        </div>
      </>)}
  ];
  
  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };
  return (
    <div>
      <h2 className="headertext">{t("projects")}</h2><hr></hr>
      <div>
      <nav className="nav-container">
          {projects.map((project) => (
            <button 
              key={project.id} 
              onClick={() => handleProjectClick(project)} 
              className="project-button"
            >
              {project.name}
            </button>
          ))}
        </nav><hr></hr>
        {selectedProject && (
          <div>         
            <p>{selectedProject.description}</p>
          </div>
        )}
      </div>
    </div>
  );
};


// const ProjectDetails = () => {
//   const { id } = useParams();
//   const { t } = useTranslation();
//   const project = projects.find((p) => p.id === id);

//   return project ? (
//     <div>
//       <h2>{project.name}</h2>
//       <p>{project.description}</p>     
//     </div>
//   ) : (
//     <h2>{t("projectnotFound")}</h2>
//   );
// };

const Navigation = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  return (
    <nav className="nav-container">
      <button className="nav-button" onClick={() => navigate("/")}>👨🏻‍💻 {t("about")}</button>
      <button className="nav-button" onClick={() => navigate("/education")}>🎓 {t("education")}</button>
      <button className="nav-button" onClick={() => navigate("/projects")}>💼 {t("projects")}</button>
      <button className="nav-button" onClick={() => navigate("/skills")}>🏆 {t("skills")}</button>
      <button className="nav-button" onClick={() => navigate("/contact")}>📧 {t("contact")}</button>
      <button className="nav-button" onClick={() => {i18n.changeLanguage(i18n.language === "pl" ? "en" : "pl"); navigate("/");}}>
        {i18n.language === "pl" ? "🇬🇧 English" : "🇵🇱 Polski"}</button>
    </nav>
  );
};

const App = () => {
  return (
    <Router>
      <div>
        <Navigation />
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/projects" element={<Projects />} />       
          <Route path="/education" element={<Education />} />
          <Route path="/skills" element={<Skills />} />
          {/* <Route path="/projects/:id" element={<ProjectDetails />} /> */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;
